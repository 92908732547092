<template>
  <div id="app">

    <Menu v-if="menu"></Menu>

    <div :class="menu ? 'main-content' : ''">
      <transition
          name="animate-ul"
          enter-active-class="animate__animated animate__slideInDown"
          leave-active-class="animate__animated animate__slideOutUp">
        <div class="alert-ul" v-if="alert">
          <div class="alert-ul__close" @click="hideAlert">
            <img src="./assets/images/close_alert.svg" alt="">
          </div>
          <div class="w-100">
            <p class="mb-0 text-center w-100" v-for="(item, index) in alert_context" :key="index+'alert'">{{ item }}</p>
          </div>
        </div>
      </transition>

      <transition name="animate-ul" enter-active-class="animate__animated animate__fadeIn" >
        <router-view></router-view>
      </transition>
    </div>

  </div>
</template>


<script>
import Menu from './components/static/Menu'
export default {
  name: 'App',
  components: {
    Menu
  },
  computed: {
    alert() {
      return this.$store.getters.getAlert()
    },
    modal() {
      return this.$store.getters.getModal()
    },
    modal_data() {
      return this.$store.getters.getModalData()
    },
    alert_context() {
      return this.$store.getters.getAlertContext()
    },
    menu() {
      if(this.$route.matched[0].props.default.menu === true){
        return true
      } else {
        return false
      }
    },

  },
  watch: {
    'alert'(){
      if(this.alert === true) {
        setTimeout(() => {
          this.$store.dispatch('HIDE_ALERT')
        }, 4000)
      }
    }
  },
  methods: {
    closeModal() {
      this.$store.dispatch('CLOSE_MODAL')
    },
    hideAlert() {
      this.$store.dispatch('HIDE_ALERT')
    }
  }
}
</script>
