<template>
  <div class="ingredients">
    <h3 class="ingredients__title">Ингредиенты</h3>
    <div class="ingredients__top">
      <div class="base-flex-space-between">
        <div class="base-flex-start">
          <h4 class="ingredients__top__title">Все ингредиенты</h4>
          <router-link :to="{name: 'add-ingredient'}" class="base-btn base-btn--violet">+ Создать</router-link>
        </div>
        <form @submit.prevent="search" class="base-flex-end">
          <input type="text" class="base-input" placeholder="Искать по названию" v-model="name_search" required>
          <button class="base-btn base-btn--violet" type="submit">Поиск</button>
        </form>
      </div>
    </div>
    <template v-if="ingredients">
      <table class="table shadow-sm mt-4">
        <thead class="border-0">
        <tr>
          <th>id</th>
          <th>Название</th>
          <th>Белки</th>
          <th>Жиры</th>
          <th>Углеводы</th>
          <th>Ккал</th>
          <th>Действия</th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="(item, index) in ingredients.data"
            :key="'ingredient'+index"
        >
          <td>{{item.id}}</td>
          <td>{{item.name}}</td>
          <td>{{item.protein}}</td>
          <td>{{item.fat}}</td>
          <td>{{item.carbohydrate}}</td>
          <td>{{item.kilocalories}}</td> 
          <td>
            <a class="base-btn border bg-light text-dark mb-1 d-inline-block" :href="$router.resolve({name: 'edit-ingredient', params: {id: item.id}}).href" target="_blank">
              Изменить
            </a>
            <router-link :to="{name: 'ingredient', params: {id: item.id}}" custom v-slot="{ navigate }">
              <button @click="navigate" @keypress.enter="navigate" class="base-btn border bg-light text-dark" role="link">Просмотр</button>
            </router-link>
          </td>
        </tr>
        </tbody>
      </table>
      <template>
        <paginate
            :page-count="Math.ceil(ingredients.meta.total/ingredients.meta.per_page)"
            :click-handler="onPaginate"
            :prev-text="'<'"
            :next-text="'>'"
            :container-class="'pagination mb-5'">
        </paginate>
      </template>
    </template>

    <Preloader v-else></Preloader>

  </div>
</template>
<script>
import Ingredients from "@/api/Ingredients";
import Preloader from './../../static/Preloader'
export default {
  components: {
    Preloader
  },
  data(){
    return {
      ingredients: null,
      errors: null,
      page: 1,
      per_page: 12,
      name_search: null
    }
  },
  watch: {
    page () {
      this.getIngredients()
    }
  },
  mounted() {
    this.getIngredients()
  },
  methods: {
    getIngredients(){
      let params = {
        page: this.page,
        per_page: this.per_page,
      }
      Ingredients.list(data=> this.ingredients = data, errors => this.errors = errors, params)
    },
    search() {
      let params = {
        page: this.page,
        per_page: this.per_page,
        name: this.name_search
      }
      Ingredients.list(data=> this.ingredients = data, errors => this.errors = errors, params)
    },
    onPaginate (page) {
      this.page = page
    }
  }
}
</script>