import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/scss/bootstrap.scss'
import 'bootstrap/dist/js/bootstrap.min'
import 'popper.js'
import 'jquery/src/jquery'
import './assets/app.scss'

import Paginate from 'vuejs-paginate'
Vue.component('paginate', Paginate)

import vSelect from "vue-select"
import "vue-select/src/scss/vue-select.scss"
Vue.component("v-select", vSelect)

import { store } from './store'
import { HTTP } from "@/http";

Vue.config.productionTip = false

if (localStorage.token) {
  HTTP.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.token
  // store.dispatch('SET_USER_FROM_TOKEN')

}


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

