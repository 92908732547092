import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


import auth from '@/store/modules/auth'
import staticFunctions from "@/store/modules/static";
import dish from "@/store/modules/dish";

export const store = new Vuex.Store({
    modules: {
        auth,
        staticFunctions,
        dish
    }
})