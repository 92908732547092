// import DailyRation from "@/api/DailyRation";
// import Recipe from "@/api/Recipe";
const state = {
    dish_id: null,
    dish_status: null,
    data: null,
    errors: null,
    modal: false,
    modal_data: null,
}

const getters = {
    dish_status: state => () => state.dish_id,
    getModal: state => () => state.modal,
    getModalData: state => () => state.modal_data
}

const mutations = {
    DISH_CHANGED(state, payload) {
        state.dish_id = payload
        // DailyRation.replaceDish(data => state.data = data, errors => state.errors = errors, state.dish_id)
    },
    OPEN_MODAL(state, payload) {
        console.log(payload)
        state.modal = true
        // Recipe.list(data => state.modal_data = data.data, errors => state.errors = errors)
    },
    CLOSE_MODAL(state) {
        state.modal = false
    }
}

const actions = {
    DISH_CHANGED: ({commit}, payload) => {
        commit('DISH_CHANGED', payload)
    },
    REPLACE_MEAL: ({commit}, payload) => {
        commit('OPEN_MODAL', payload)
    },
    CLOSE_MODAL: ({commit}, payload = null) => {
        commit('CLOSE_MODAL', payload)
    }
}


export default {
    state,
    getters,
    actions,
    mutations
}
