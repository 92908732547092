<template>
  <div class="ingredients">
    <h3 class="ingredients__title">Блюда</h3>
    <div class="ingredients__top">
      <div class="base-flex-space-between">
        <div class="base-flex-start">
          <h4 class="ingredients__top__title">Все блюда</h4>
          <router-link :to="{name: 'add-dish'}" class="base-btn base-btn--violet mr-2 px-3">+ Создать</router-link>
          <router-link :to="{name: 'create-dish-by-ingredients'}" class="base-btn px-3 base-btn--violet">+ Создать блюдо по ингредиентам</router-link>
        </div>
        <form @submit.prevent="getDishes" class="base-flex-end">
          <input type="text" class="base-input" placeholder="Искать по названию" v-model="name_search" required>
          <button class="base-btn base-btn--violet" type="submit">Поиск</button>
        </form>
      </div>
      <div class="w-50 py-3">
        <template v-if="categories">
          <div class="base-flex-space-between mt-2">
            <p class="mb-0 mr-4 base-bold">Категория: </p>
            <select class="base-select" v-model="dish_select_category">
              <option :value="null" selected style="display: none">Выберите категорию</option>
              <option
                  v-for="(item, index) in categories"
                  :key="'category'+index"
                  :value="item"
              >{{ item.name }}</option>
            </select>
          </div>
          <div class="pt-3" v-if="dish_selected_categories.length">
            <span class="tag" v-for="(item, index) in dish_selected_categories" :key="'category'+index">{{item.name}}
              <img @click="deleteDishCategory(index)" class="tag__close" src="../../../assets/images/close.png"  alt="X">
            </span>
          </div>
        </template>
      </div>
    </div>
    <template v-if="dishes">
      <table class="table shadow-sm mt-4">
        <thead class="border-0">
        <tr>
          <th>id</th>
          <th>Название</th>
          <th>Белки</th>
          <th>Жиры</th>
          <th>Углеводы</th>
          <th>Ккал</th>
<!--          <th>Категория</th>-->
          <th>Действия</th>
        </tr>
        </thead>
        <tbody >
        <tr
            v-for="(item, index) in dishes.data"
            :key="'dish'+index"
        >
          <td>{{item.id}}</td>
          <td>{{item.name}}</td>
          <td>{{item.macronutrients.protein}}</td>
          <td>{{item.macronutrients.fat}}</td>
          <td>{{item.macronutrients.carbohydrate}}</td>
          <td>{{item.macronutrients.kilocalories}}</td>
<!--          <td v-if="item.category">-->
<!--            <span class="tag" v-for="(category, index_category) in item.category" :key="'category_of_dish'+index_category">{{category.name}}</span>-->
<!--          </td>-->
          <td>
            <a class="base-btn border bg-light text-dark mb-1 d-inline-block"
               :href="$router.resolve({name: 'edit-dish', params: {id: item.id}}).href" target="_blank">
              Изменить
            </a>
            <router-link :to="{name: 'dish', params: {id: item.id}}" custom v-slot="{ navigate }">
              <button @click="navigate" @keypress.enter="navigate" class="base-btn border bg-light text-dark" role="link">Просмотр</button>
            </router-link>
          </td>
        </tr>
        </tbody>
      </table>
      <template>
        <paginate
            :page-count="Math.ceil(dishes.meta.total/dishes.meta.per_page)"
            :click-handler="onPaginate"
            :prev-text="'<'"
            :next-text="'>'"
            :container-class="'pagination mb-5'">
        </paginate>
      </template>
    </template>

    <Preloader v-else></Preloader>

  </div>
</template>
<script>
import Preloader from './../../static/Preloader'
import Dishes from '@/api/Dishes'
export default {
  components: {
    Preloader
  },
  data(){
    return {
      dishes: null,
      errors: null,
      page: 1,
      per_page: 12,
      name_search: null,

      categories: null,
      dish_categories: null,
      dish_select_category: null,
      dish_selected_categories: [],
    }
  },
  watch: {
    page () {
      this.getDishes()
    },
    'dish_select_category'() {
        if (this.dish_select_category){
          this.dish_selected_categories.push(this.dish_select_category)
        }
      setTimeout(() => {
        this.getDishes()
      })
    },
  },
  mounted() {
    this.getDishes()
    this.getDishCategories()
  },
  methods: {
    deleteDishCategory(index){
      this.dish_selected_categories.splice(index,1);
      this.dish_select_category = null
    },
    getDishes(){
      let params = {
        page: this.page,
        per_page: this.per_page,
      }
      if (this.name_search){
        params.name = this.name_search
      }
      if (this.dish_selected_categories.length){
        let categories_for_params = []
        this.dish_selected_categories.map((item) => {
          categories_for_params.push(item.id)
        })
        params.dish_category = categories_for_params
      }
      setTimeout(() => {
        this.getListOfDishes(params)
      })
    },
    getListOfDishes(params){
      Dishes.list(data=> this.dishes = data, errors => this.errors = errors, params)
    },
    // getDishesByCategory(){
    //   let categories_for_params = []
    //   this.dish_selected_categories.map((item) => {
    //     categories_for_params.push(item.id)
    //   })
    //   let params = {
    //     page: this.page,
    //     per_page: this.per_page,
    //     dish_category: categories_for_params
    //   }
    //   setTimeout(() => {
    //     Dishes.list(data=> this.dishes = data, errors => this.errors = errors, params)
    //   })
    // },
    // search() {
    //   let params = {
    //     page: this.page,
    //     per_page: this.per_page,
    //     name: this.name_search
    //   }
    //   Dishes.list(data=> this.dishes = data, errors => this.errors = errors, params)
    // },
    onPaginate (page) {
      this.page = page
    },
    getDishCategories(){
      Dishes.dishCategories(data=> this.categories = data, errors => this.errors = errors)
    },
  }
}
</script>