<template>
  <div class="ingredients single add-ingredient">
    <h3 class="ingredients__title">Изменить ингредиент</h3>
    <template v-if="data">
      <form @submit.prevent="updateIngredient">
        <div class="base-flex-start py-5">
          <h4 class="mr-5">{{data.name}}</h4>
          <button class="base-btn btn-danger mr-2" @click="deleteIngredient">Удалить</button>
          <router-link :to="{name: 'ingredients'}" class="base-btn btn-warning">Вернуться к списку</router-link>
        </div>
        <div>
          <div class="base-flex-space-between shadow-sm p-2">
            <p class="mb-0 base-bold">Название: </p>
            <input v-model="data.name" type="text" class="base-input" placeholder="Имя ингредиента" required>
          </div>
          <div class="base-flex-space-between shadow-sm p-2">
            <p class="mb-0 base-bold">Категория: </p>
            <select class="base-select" v-model="data.category.id" >
              <option :value="null" selected style="display: none">Выберите категорию</option>
              <option
                  v-for="(item, index) in ingredient_categories"
                  :key="'category'+index"
                  :value="item.id"
              >{{ item.name }}</option>
            </select>
          </div>
          <div class="base-flex-space-between shadow-sm p-2">
            <p class="mb-0 base-bold">Белки: </p>
            <input v-model="data.protein" type="text" class="base-input" placeholder="Имя ингредиента" required>
          </div>
          <div class="base-flex-space-between shadow-sm p-2">
            <p class="mb-0 base-bold">Жиры: </p>
            <input v-model="data.fat" type="text" class="base-input" placeholder="Имя ингредиента" required>
          </div>
          <div class="base-flex-space-between shadow-sm p-2">
            <p class="mb-0 base-bold">Углеводы: </p>
            <input v-model="data.carbohydrate" type="text" class="base-input" placeholder="Имя ингредиента" required>
          </div>
          <div class="base-flex-space-between shadow-sm p-2">
            <p class="mb-0 base-bold">Ккал: </p>
            <input v-model="data.kilocalories" type="text" class="base-input" placeholder="Имя ингредиента" required>
          </div>
          <div class="base-flex-space-between shadow-sm p-2">
            <p class="mb-0 base-bold">Glycemic index: </p>
            <input v-model="data.glycemic_index" type="text" class="base-input" placeholder="Имя ингредиента" required>
          </div>
          <div class="px-2 py-3 shadow-sm base-flex-space-between">
            <div>
              <input type="checkbox" id="exotic" name="is_exotic" v-model="data.is_exotic" >
              <label for="exotic" class="add-ingredient__exotic base-bold">Экзотический</label>
            </div>
            <div v-if="data.is_exotic" class="add-ingredient__months">
              <span v-for="(item, index) in months" :key="'month'+index" class="checkbox-ul">
                <input type="checkbox" :id="'month'+item.id" name="month" :value="item.id"
                       v-model="ingredient.months"
                >
                <label :for="'month'+item.id"> {{ item.name }} </label><br>
              </span>
            </div>
          </div>
        </div> 

        <div>
          <p class=" mb-0 pb-3 pt-5 base-bold">Микронутриенты</p>
          <table class="table table-bordered">
            <thead>
            <tr>
              <th>Название</th>
              <th>Количество</th>
              <th> </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item,index) in data.micronutrients" :key="'ing'+index">
              <td>
                {{item.name}}
              </td>
              <td>
                {{item.pivot.weight}}
              </td>
              <td>
                <button class="base-btn  border bg-light" @click="deleteMicronutrient(index)">Удалить</button>
              </td>
            </tr>
            <tr>
              <td>
                <select class="form-control" v-model="micronutrient">
                  <option :value="null" selected style="display: none">Выберите микронутриент</option>
                  <option
                      v-for="(item, index) in micro_nutrients"
                      :key="'micronutrients'+index"
                      :value="item"
                  >{{ item.name }}</option>
                </select>
              </td>
              <td>
                <input type="text" class="form-control" :placeholder="micronutrient_placeholder" v-model="micronutrient_value">
              </td>
              <td>
                <button class="base-btn bg-light border" @click="addMicronutrient" type="button">Добавить</button>
              </td>
            </tr>

            </tbody>
          </table>
        </div>
        <div class="w-100 base-flex-end pt-3 pb-5">
          <button class="base-btn border bg-light" type="submit">Сохранить</button>
        </div>
      </form>

    </template>

    <Preloader v-else></Preloader>
  </div>
</template>
<script>
import Ingredients from "@/api/Ingredients";
import Preloader from './../../static/Preloader'
export default {
  components: {
    Preloader
  },
  data(){
    return {
      ingredient_categories: null,
      months: null,

      micro_nutrients: null,
      micronutrient: null,
      micronutrient_placeholder: null,
      micronutrient_value: null,

      ingredient: {
        months:[],
      },

      errors: null,
      data: null,
      result: null
    }
  },
  watch: {
    'data'(){
      this.data.months.map((item) => {
        this.ingredient.months.push(item.id)
      })
    },
    'micronutrient'(){
      this.micronutrient_placeholder = this.micronutrient ? this.micronutrient.unit_type.name : null
    },
    'data.protein'(){
      if (this.data.protein !== null && this.data.carbohydrate !== null && this.data.fat !== null) {
        this.data.kilocalories = (4 * this.data.protein) + (4 * this.data.carbohydrate) + (9 * this.data.fat)
      }
    },
    'data.carbohydrate'(){
      if (this.data.protein !== null && this.data.carbohydrate !== null && this.data.fat !== null) {
        this.data.kilocalories = (4 * this.data.protein) + (4 * this.data.carbohydrate) + (9 * this.data.fat)
      }
    },
    'data.fat'(){
      if (this.data.protein !== null && this.data.carbohydrate !== null && this.data.fat !== null) {
        this.data.kilocalories = (4 * this.data.protein) + (4 * this.data.carbohydrate) + (9 * this.data.fat)
      }
    }

  },
  mounted() {
    this.getIngredient()
    this.getIngredientCategories()
    this.getMonth()
    this.getMicroNutrients()
  },
  methods: {

    getIngredient(){
      Ingredients.show(data=> this.data = data, errors => this.errors = errors, this.$route.params.id)
    },
    updateIngredient(){
      let micronutrients_selected = []
      this.data.micronutrients.map((item) => {
        micronutrients_selected.push({
          micro_id: item.pivot.micro_id,
          weight: parseInt(item.pivot.weight)
        })
      })
      // console.log(this.data)
      Ingredients.update(data => {this.result = data}, errors => { this.errors = errors }, this.$route.params.id,
          {
            name: this.data.name,
            category_id:this.data.category.id,
            protein:this.data.protein,
            carbohydrate:this.data.carbohydrate,
            fat:this.data.fat,
            kilocalories:this.data.kilocalories,
            glycemic_index:this.data.glycemic_index,
            is_exotic:this.data.is_exotic,
            months:this.ingredient.months,
            micronutrients:micronutrients_selected
          })
          .then(()=> {
            if (this.result.status === "resource updated successfully") {
              this.$router.push({ name: 'ingredients' })
              this.$store.dispatch('SHOW_ALERT', ['Ингредиент успешно обновлен'])
            }
          })
    },
    addMicronutrient(){
      if (this.micronutrient_value){
        this.data.micronutrients.push({
          micro_id: this.micronutrient.id,
          name: this.micronutrient.name,
          pivot: {
            micro_id: this.micronutrient.id,
            weight: this.micronutrient_value,
          }
        })
        setTimeout(() =>{
          this.micronutrient_value = null
          this.micronutrient = null
        })
      }
    },
    deleteMicronutrient(index){
      this.data.micronutrients.splice(index,1);
    },
    getIngredientCategories(){
      Ingredients.category_list(data=> this.ingredient_categories = data, errors => this.errors = errors)
    },
    getMonth() {
      Ingredients.months(data=> this.months = data, errors => this.errors = errors)
    },
    getMicroNutrients(){
      Ingredients.micro_nutrients(data=> this.micro_nutrients = data, errors => this.errors = errors)
    },
    deleteIngredient(){
      Ingredients.delete(data=> this.data = data, errors => this.errors = errors, this.$route.params.id)
          .then(()=>{
            if (this.data.status === 'resource deleted successfully'){
              this.$store.dispatch('SHOW_ALERT', ['Ингредиент успешно удален'])
              this.$router.push({ name: 'ingredients' })
            }
          })
    }
  }
}
</script>