<template>
  <div class="base-flex-space-between">
    <h3>
      Обзор
    </h3>
    <button class="btn btn-dark border" @click="logout">Logout</button>
  </div>
</template>
<script>
export default {
  methods: {
    logout(){
      this.$store.dispatch('SET_USER')
      window.location.reload()
    },
  }
}
</script>