import Vue from 'vue'
import Router from 'vue-router'

import Main from '@/components/pages/Main'

import Ingredients from '@/components/pages/ingredients/Index'
import Ingredient from '@/components/pages/ingredients/Single'
import AddIngredient from '@/components/pages/ingredients/Add'
import EditIngredient from '@/components/pages/ingredients/Edit'

import Dishes from '@/components/pages/dishes/Index'
import Dish from '@/components/pages/dishes/Single'
import AddDish from '@/components/pages/dishes/Add'
import EditDish from '@/components/pages/dishes/Edit'
import CreateByIngredients from '@/components/pages/dishes/createByIngredients'

import SignIn from "@/components/pages/SignIn";

Vue.use(Router)

function requireAuth(to, from, next) {
    if (localStorage.token) {
        next()
    } else {
        next({
            name: 'sign-in'
        })
    }
}


export default new Router({
    routes: [
        {
            path: '/',
            name: 'main',
            component: Main,
            beforeEnter: requireAuth,
            props: {
                menu: true
            },
        },
        {
            path: '/dishes',
            name: 'dishes',
            component: Dishes,
            beforeEnter: requireAuth,
            props: {
                menu: true
            },
        },
        {
            path: '/dish/:id',
            name: 'dish',
            component: Dish,
            beforeEnter: requireAuth,
            props: {
                menu: true
            },
        },
        {
            path: '/dishes/add',
            name: 'add-dish',
            component: AddDish,
            beforeEnter: requireAuth,
            props: {
                menu: true
            },
        },
        {
            path: '/dishes/create-by-ingredients',
            name: 'create-dish-by-ingredients',
            component: CreateByIngredients,
            beforeEnter: requireAuth,
            props: {
                menu: true
            },
        },
        {
            path: '/dish/edit/:id',
            name: 'edit-dish',
            component: EditDish,
            beforeEnter: requireAuth,
            props: {
                menu: true
            },
        },
        {
            path: '/ingredients',
            name: 'ingredients',
            component: Ingredients,
            beforeEnter: requireAuth,
            props: {
                menu: true
            },
        },
        {
            path: '/ingredient/:id',
            name: 'ingredient',
            component: Ingredient,
            beforeEnter: requireAuth,
            props: {
                menu: true
            },
        },
        {
            path: '/ingredient/edit/:id',
            name: 'edit-ingredient',
            component: EditIngredient,
            beforeEnter: requireAuth,
            props: {
                menu: true
            },
        },
        {
            path: '/ingredients/add',
            name: 'add-ingredient',
            component: AddIngredient,
            beforeEnter: requireAuth,
            props: {
                menu: true
            },
        },
        {
            path: '/sign-in',
            name: 'sign-in',
            component: SignIn,
            props: {
                menu: false
            },
        },
        {
            path: '*',
            redirect: '/'
        }
    ],
    mode: 'history',
    linkExactActiveClass: 'exact-active',
    linkActiveClass: 'active',
    // eslint-disable-next-line no-unused-vars
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
})
