import {HTTP} from '@/http'

class Ingredients {
    static add(then, catcher, params = null) {
        return HTTP.post('/admin/ingredients', params)
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }
    static update(then, catcher, id, params = null) {
        return HTTP.put('/admin/ingredients/'+ id, params)
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }

    static list(then, catcher, params = null) {
        return HTTP.get('/admin/ingredients', {params})
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }
    static show(then, catcher, id,  params = null) {
            return HTTP.get('/admin/ingredients/'+ id, params)
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }
    static delete(then, catcher, id,  params = null) {
        return HTTP.delete('/admin/ingredients/'+ id, params)
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }

    static category_list(then, catcher, params = null) {
        return HTTP.get('/admin/ingredient-categories', {params})
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }
    static months(then, catcher, params = null) {
        return HTTP.get('/admin/months', {params})
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }
    static micro_nutrients(then, catcher, params = null) {
        return HTTP.get('/admin/micro-nutrients', {params})
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }

    static cooking_types(then, catcher, params = null) {
        return HTTP.get('/admin/cooking-types', {params})
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }

}
export default Ingredients