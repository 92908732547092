<template>
  <form @submit.prevent="addMicronutrient" class="ingredients add-ingredient">
    <h3 class="ingredients__title">Новый ингредиент</h3>
    <div class="base-flex-space-between mt-4">
      <p class="mb-0">Название: </p>
      <input v-model="ingredient.name" type="text" class="base-input" placeholder="Имя ингредиента" required>
    </div>
    <div class="base-flex-space-between mt-2">
      <p class="mb-0">Категория: </p>
      <select class="base-select" v-model="ingredient.category_id">
        <option :value="null" selected style="display: none">Выберите категорию</option>
        <option
            v-for="(item, index) in ingredient_categories"
            :key="'category'+index"
            :value="item.id"
        >{{ item.name }}</option>
      </select>
    </div>
    <div class="mt-2 base-flex-space-between">
      <div>
        <input type="checkbox" id="exotic" name="is_exotic" v-model="ingredient.is_exotic" >
        <label for="exotic" class="add-ingredient__exotic">Экзотический</label>
      </div>
      <div v-if="ingredient.is_exotic" class="add-ingredient__months">
        <span v-for="(item, index) in months" :key="'month'+index" class="checkbox-ul">
          <input type="checkbox" :id="'month'+item.id" name="month" :value="item.id" v-model="ingredient.months" >
          <label :for="'month'+item.id"> {{ item.name }} </label><br>
        </span>
      </div>
    </div>
    <div class="py-4 w-100">
      <div class="row">
        <div class="col mr-2">
          <label class="mr-2">Белки</label>
          <input type="text"  placeholder="" class="base-input" v-model="ingredient.protein" required>
        </div>
        <div class="col mr-2">
          <label class="mr-2">Жиры</label>
          <input type="text"  placeholder="" class="base-input" v-model="ingredient.fat" required>
        </div>
        <div class="col">
          <label class="mr-2">Углеводы</label>
          <input type="text"  placeholder="" class="base-input" v-model="ingredient.carbohydrate" required>
        </div>
      </div>
      <div class="base-flex-space-around mt-3  w-100">
        <div class="col ">
          <label class="mr-2">Energy amount</label>
          <input type="text"  placeholder="" class="base-input "  v-model="ingredient.kilocalories" required>
        </div>
        <div class="col ">
          <label class="mr-2">Glycemic index</label>
          <input type="text"  placeholder="" class="base-input " v-model="ingredient.glycemic_index" required>
        </div>
      </div>
    </div>
    <div class="py-4">
      <div class="base-flex-start mb-3">
        <p class="mb-0 mr-5">Микронутриенты</p>
        <button class="base-btn base-btn--violet" @click="add_micronutrient = true" type="button">+ Добавить</button>
      </div>
      <table class="table table-bordered" v-if="add_micronutrient">
        <thead>
        <tr>
          <th>Название</th>
          <th>Количество</th>
          <th> </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item,index) in ingredient.micronutrients" :key="'ing'+index">
          <td>
             {{item.name}}
          </td>
          <td>
            {{item.weight}} {{item.weight_type}}
          </td>
          <td>
            <button class="base-btn  border bg-light" @click="deleteMicronutrient(index)">Удалить</button>
          </td>
        </tr>
        <tr>
          <td>
            <select class="form-control" v-model="micronutrient">
              <option :value="null" selected style="display: none">Выберите микронутриент</option>
              <option
                  v-for="(item, index) in micro_nutrients"
                  :key="'micronutrients'+index"
                  :value="item"
              >{{ item.name }}</option>
            </select>
          </td>
          <td>
            <input type="text" class="form-control" :placeholder="micronutrient_placeholder" v-model.lazy="micronutrient_value">
          </td>
          <td>
            <button class="base-btn bg-light border" type="button">Добавить</button>
          </td>
        </tr>

        </tbody>
      </table>
    </div>

    <div class="w-100 base-flex-end pt-3 pb-5">
      <button class="base-btn border bg-light" >Добавить ингредиент</button>
    </div>
    <Preloader v-if="false"></Preloader>

  </form>
</template>
<script>
import Ingredients from "@/api/Ingredients";
import Preloader from './../../static/Preloader'
export default {
  components: {
    Preloader
  },
  data(){
    return {
      ingredient_categories: null,
      months: null,
      micro_nutrients: null,
      micronutrient: null,
      micronutrient_placeholder: null,
      micronutrient_value: null,
      add_micronutrient: false,
      ingredient: {
        name: null,
        category_id:null,
        protein:null,
        carbohydrate:null,
        fat:null,
        kilocalories:null,
        glycemic_index:null,
        is_exotic:false,
        months:[],
        micronutrients:[]
      },
      errors: null,
      data: null,
    }
  },
  watch: {
    'micronutrient'(){
      this.micronutrient_placeholder = this.micronutrient ? this.micronutrient.unit_type.name : null
    },
    'micronutrient_value'(){
      if (this.micronutrient_value){
        this.ingredient.micronutrients.push({
          micro_id: this.micronutrient.id,
          weight: this.micronutrient_value,
          name: this.micronutrient.name,
          weight_type: this.micronutrient.unit_type.name
        })
        setTimeout(() =>{
          this.micronutrient_value = null
          this.micronutrient = null
        })
      }
    },
    page () {
      this.getIngredients()
    },
    'ingredient.protein'(){
      if (this.ingredient.protein !== null && this.ingredient.carbohydrate !== null && this.ingredient.fat !== null) {
        this.ingredient.kilocalories = (4 * this.ingredient.protein) + (4 * this.ingredient.carbohydrate) + (9 * this.ingredient.fat)
      }
    },
    'ingredient.carbohydrate'(){
      if (this.ingredient.protein !== null && this.ingredient.carbohydrate !== null && this.ingredient.fat !== null) {
        this.ingredient.kilocalories = (4 * this.ingredient.protein) + (4 * this.ingredient.carbohydrate) + (9 * this.ingredient.fat)
      }
    },
    'ingredient.fat'(){
      if (this.ingredient.protein !== null && this.ingredient.carbohydrate !== null && this.ingredient.fat !== null) {
        this.ingredient.kilocalories = (4 * this.ingredient.protein) + (4 * this.ingredient.carbohydrate) + (9 * this.ingredient.fat)
      }
    }

  },
  mounted() {
    this.getIngredientCategories()
    this.getMonth()
    this.getMicroNutrients()
  },
  methods: {
    addMicronutrient(){
      let micronutrients_selected = []
      this.ingredient.micronutrients.map((item) => {
        micronutrients_selected.push({
          micro_id: item.micro_id,
          weight: parseInt(item.weight)
        })
      })
        Ingredients.add(data => {this.data = data}, errors => { this.errors = errors },
            {
              name: this.ingredient.name,
              category_id:this.ingredient.category_id,
              protein:this.ingredient.protein,
              carbohydrate:this.ingredient.carbohydrate,
              fat:this.ingredient.fat,
              kilocalories:this.ingredient.kilocalories,
              glycemic_index:this.ingredient.glycemic_index,
              is_exotic:this.ingredient.is_exotic,
              months:this.ingredient.months,
              micronutrients:micronutrients_selected
            })
            .then(()=> {
              if (this.data.status === "resource created successfully") {
                this.$router.push({ name: 'ingredients' })
              }
            })
    },
    deleteMicronutrient(index){
      this.ingredient.micronutrients.splice(index,1);
    },
    getIngredientCategories(){
      Ingredients.category_list(data=> this.ingredient_categories = data, errors => this.errors = errors)
    },
    getMonth() {
      Ingredients.months(data=> this.months = data, errors => this.errors = errors)
    },
    getMicroNutrients(){
      Ingredients.micro_nutrients(data=> this.micro_nutrients = data, errors => this.errors = errors)
    },
  }
}
</script>