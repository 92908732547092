


const state = {
    alert: false,
    alert_context: null
}

const getters = {
    getAlert: state => () => state.alert,
    getAlertContext: state => () => state.alert_context
}

const mutations = {
    SHOW_ALERT(state, payload) {
        state.alert = true
        state.alert_context = payload
    },
    HIDE_ALERT(state) {
        state.alert = false
    }
}

const actions = {
    HIDE_ALERT: ({commit}) => {
        commit('HIDE_ALERT')
    },
    SHOW_ALERT: ({commit}, payload) => {
        commit('SHOW_ALERT', payload)
        // console.log(payload)
    }
}


export default {
    state,
    getters,
    actions,
    mutations
}
