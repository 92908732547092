<template>
  <form @submit.prevent="addDish" class="ingredients add-ingredient">
    <h3 class="ingredients__title">Новое Блюдо</h3>
    <div class="base-flex-space-between mt-4">
      <p class="mb-0">Название: </p>
      <input v-model="dish.name" type="text" class="base-input" placeholder="Название блюда" required>
    </div>
    <div class="base-flex-space-between mt-2">
      <p class="mb-0">Описание: </p>
      <textarea  class="base-input rounded" v-model="dish.description" rows="2" cols="50" placeholder="Описание блюда ..." required>  </textarea>
    </div>
    <div class="base-flex-space-between mt-4">
      <p class="mb-0">Ссылка на рецепт: </p>
      <input v-model="dish.recipe_link" type="text" class="base-input" placeholder="Название блюда" required>
    </div>
<!--    Category-->
    <template>
      <div class="base-flex-space-between mt-2">
        <p class="mb-0">Категория: </p>
        <select class="base-select" v-model="dish_select_category">
          <option :value="null" selected style="display: none">Выберите категорию</option>
          <option
              v-for="(item, index) in dish_categories"
              :key="'category'+index"
              :value="item"
          >{{ item.name }}</option>
        </select>
      </div>
      <div class="py-3">
        <span class="tag" v-for="(item, index) in dish_selected_categories" :key="'category'+index">{{item.name}}
          <img @click="deleteDishCategory(index)" class="tag__close" src="../../../assets/images/close.png"  alt="X">
        </span>
      </div>
    </template>

    <!--    Types-->
    <template>
      <div class="base-flex-space-between mt-2">
        <p class="mb-0">Типы: </p>
        <select class="base-select" v-model="type_select">
          <option :value="null" selected style="display: none">Выберите тип</option>
          <option
              v-for="(item, index) in types"
              :key="'category'+index"
              :value="item"
          >{{ item.name }}</option>
        </select>
      </div>
      <div class="py-3">
        <span class="tag" v-for="(item, index) in types_selected" :key="'type'+index">{{item.name}}
          <img @click="deleteDishType(index)" class="tag__close" src="../../../assets/images/close.png"  alt="X">
        </span>
      </div>
    </template>

    <div class="base-flex-space-between mt-4">
      <p class="mb-0">Рецепт: </p>
      <textarea  class="base-input rounded" v-model="recipe_description" rows="2" cols="50" placeholder="Шаг 1" required>  </textarea>

    </div>
    <div class="base-flex-space-between mt-4">
      <p class="mb-0">Вес: </p>
      <input v-model="dish.weight" type="text" class="base-input" placeholder="Вес блюда" required>
    </div>

    <div class="w-100 base-flex-end pt-3 pb-5">
<!--      <button class="base-btn base-btn&#45;&#45;violet"   type="button">+ Добавить шаг</button>-->
    </div>

    <div class="mt-2 base-flex-space-between">
      <div>
        <input type="checkbox" id="exotic" name="is_exotic" v-model="dish.is_exotic" >
        <label for="exotic" class="add-ingredient__exotic">Экзотический</label>
      </div>
<!--      <div v-if="dish.is_exotic" class="add-ingredient__months">-->
<!--        <span v-for="(item, index) in months" :key="'month'+index" class="checkbox-ul">-->
<!--          <input type="checkbox" :id="'month'+item.id" name="month" :value="item.id" v-model="ingredient.months" >-->
<!--          <label :for="'month'+item.id"> {{ item.name }} </label><br>-->
<!--        </span>-->
<!--      </div>-->
    </div>
    <div class="mt-2 base-flex-space-between">
      <div>
        <input type="checkbox" id="active" name="is_active" v-model="dish.is_active" >
        <label for="active" class="add-ingredient__exotic">Активен</label>
      </div>
    </div>
    <div class="mt-2 base-flex-space-between">
      <div>
        <input type="checkbox" id="micro" name="micro" v-model="dish.is_micronutrients" >
        <label for="micro" class="add-ingredient__exotic">Микронутриенты</label>
      </div>
    </div>
    <div class="py-4 w-100">
      <div class="row">
        <div class="col mr-2">
          <label class="mr-2">Белки</label>
          <input type="text"  placeholder="" class="base-input" v-model="dish.protein" required>
        </div>
        <div class="col mr-2">
          <label class="mr-2">Жиры</label>
          <input type="text"  placeholder="" class="base-input" v-model="dish.fat" required>
        </div>
        <div class="col">
          <label class="mr-2">Углеводы</label>
          <input type="text"  placeholder="" class="base-input" v-model="dish.carbohydrate" required>
        </div>
      </div>
      <div class="base-flex-space-around mt-3  w-100">
        <div class="col ">
          <label class="mr-2">Energy amount</label>
          <input type="text"  placeholder="" class="base-input "  v-model="dish.kilocalories" required>
        </div>
        <div class="col ">
          <label class="mr-2">Glycemic index</label>
          <input type="text"  placeholder="" class="base-input " v-model="dish.glycemic_index" required>
        </div>
      </div>
    </div>
    <div class="py-4">
      <div class="base-flex-start mb-3">
        <h5 class="mb-0 mr-5">Микронутриенты</h5>
        <button class="base-btn base-btn--violet" @click="add_micronutrient = true" type="button">+ Добавить</button>
      </div>
      <table class="table table-bordered" v-if="add_micronutrient">
        <thead>
        <tr>
          <th>Название</th>
          <th>Количество</th>
          <th> </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item,index) in ingredient.micronutrients" :key="'ing'+index">
          <td>
            {{item.name}}
          </td>
          <td>
            {{item.weight}} {{item.weight_type}}
          </td>
          <td>
            <button class="base-btn  border bg-light" @click="deleteMicronutrient(index)">Удалить</button>
          </td>
        </tr>
        <tr>
          <td>
            <select class="form-control" v-model="micronutrient">
              <option :value="null" selected style="display: none">Выберите микронутриент</option>
              <option
                  v-for="(item, index) in micro_nutrients"
                  :key="'micronutrients'+index"
                  :value="item"
              >{{ item.name }}</option>
            </select>
          </td>
          <td>
            <input type="text" class="form-control" :placeholder="micronutrient_placeholder" v-model.lazy="micronutrient_value">
          </td>
          <td>
            <button class="base-btn bg-light border">Добавить</button>
          </td>
        </tr>

        </tbody>
      </table>
    </div>


    <div class="py-4">
      <div class="base-flex-start mb-3">
        <h5 class="mb-0 mr-5">Ингредиенты </h5>
        <button class="base-btn base-btn--violet" @click="add_unit_type = true" type="button">+ Добавить</button>
      </div>
      <table class="table table-bordered" v-if="add_unit_type">
        <thead>
        <tr>
          <th>Название</th>
          <th>Масса</th>
          <th>Количество</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item,index) in dish.ingredients" :key="'ing'+index">
          <td>
            {{item.name}}
          </td>
          <td>
            {{item.unit_type_name}}
          </td>
          <td>
            {{item.weight}}
          </td>
          <td>
            <button class="base-btn  border bg-light" @click="deleteIngredient(index)">Удалить</button>
          </td>
        </tr>
        <tr>
          <td>

            <v-select  v-model="ingredient_selected" :options="options" style="width: 200px;" placeholder="Выберите ингредиент" label="name" @search="fetchOptions">
              <template v-slot:option="option">
                {{ option.name }}
              </template>
            </v-select>
          </td>
          <td>
            <select class="form-control" v-model="unit_type">
              <option :value="null" selected style="display: none">Выберите тип</option>
              <option
                  v-for="(item, index) in unit_types"
                  :key="'unit_type'+index"
                  :value="item"
              >{{ item.name }}</option>
            </select>
          </td>
          <td>
            <input type="text" class="form-control" v-model.lazy="unit_type_value">
          </td>
          <td>
            <button class="base-btn bg-light border">Добавить</button>
          </td>
        </tr>

        </tbody>
      </table>
    </div>

    <div class="w-100 base-flex-end pt-3 pb-5">
      <button class="base-btn border bg-light">Добавить блюдо</button>
    </div>
    <Preloader v-if="false"></Preloader>

  </form>
</template>
<script>
import Dishes from "@/api/Dishes";
import Preloader from './../../static/Preloader'
import Ingredients from "@/api/Ingredients"
export default {
  components: {
    Preloader
  },
  data(){
    return {
      dish_categories: null,
      dish_select_category: null,
      dish_selected_categories: [],

      types: null,
      type_select: null,
      types_selected: [],

      recipe_description: null,

      micro_nutrients: null,
      micronutrient: null,
      micronutrient_value: null,
      micronutrient_placeholder: null,
      add_micronutrient: false,

      unit_types: null,
      unit_type: null,
      unit_type_value: null,
      add_unit_type: false,

      search_ingredient_name: null,
      options: [

      ],

      ingredient_selected: null,
      ingredient_categories: null,
      ingredient: {
        micronutrients:[]
      },

      dish: {
        name: null,
        description: null,
        recipe_link: null,
        protein:null,
        carbohydrate:null,
        fat:null,
        kilocalories:null,
        glycemic_index:null,
        is_exotic:false,
        is_active: true,
        is_micronutrients: true,
        weight: null,
        ingredients: [],

      },

      errors: null,
      data: null,
    }
  },
  watch: {
    'ingredient_categories'(){
      this.options = this.ingredient_categories
    },
    'unit_type_value'(){
      if (this.unit_type_value && this.ingredient_selected !== null){
        this.dish.ingredients.push({
          name: this.ingredient_selected.name,
          ingredient_id: this.ingredient_selected.id,
          weight: this.unit_type_value,
          unit_type_name: this.unit_type.name,
          unit_type_id: this.unit_type.id
        })
        setTimeout(() =>{
          this.ingredient_selected = null
          this.unit_type_value = null
          this.unit_type = null
        })
      }
    },
    'dish_select_category'() {
      this.dish_selected_categories.push(this.dish_select_category)
    },
    'type_select'() {
      this.types_selected.push(this.type_select)
    },
    'micronutrient'(){
      this.micronutrient_placeholder = this.micronutrient ? this.micronutrient.unit_type.name : null
    },
    'micronutrient_value'(){
      if (this.micronutrient_value){
        this.ingredient.micronutrients.push({
          micro_id: this.micronutrient.id,
          weight: this.micronutrient_value,
          name: this.micronutrient.name,
          weight_type: this.micronutrient.unit_type.name
        })
        setTimeout(() =>{
          this.micronutrient_value = null
          this.micronutrient = null
        })
      }
    },
    'dish.protein'(){
      if (this.dish.protein !== null && this.dish.carbohydrate !== null && this.dish.fat !== null) {
        this.dish.kilocalories = (4 * this.dish.protein) + (4 * this.dish.carbohydrate) + (9 * this.dish.fat)
      }
    },
    'dish.carbohydrate'(){
      if (this.dish.protein !== null && this.dish.carbohydrate !== null && this.dish.fat !== null) {
        this.dish.kilocalories = (4 * this.dish.protein) + (4 * this.dish.carbohydrate) + (9 * this.dish.fat)
      }
    },
    'dish.fat'(){
      if (this.dish.protein !== null && this.dish.carbohydrate !== null && this.dish.fat !== null) {
        this.dish.kilocalories = (4 * this.dish.protein) + (4 * this.dish.carbohydrate) + (9 * this.dish.fat)
      }
    }

  },
  mounted() {
    this.getCategories()
    this.getIngredientCategories()
    this.getTypes()
    this.getUnitTypes()
    this.getMicroNutrients()
  },
  methods: {
    addDish(){
      let micronutrients_for_params = []
      this.ingredient.micronutrients.map((item) => {
        micronutrients_for_params.push({
          micro_id: item.micro_id,
          weight: parseInt(item.weight)
        })
      })

      let dish_categories_for_params = []
      this.dish_selected_categories.map((item) => {
       dish_categories_for_params.push({
         dish_category_id:item.id
       })
      })

      let dish_types_for_params = []
      this.types_selected.map((item) => {
        dish_types_for_params.push({
          dish_type_id:item.id
        })
      })

      let dish_ingredients_for_params = []
      this.dish.ingredients.map((item) => {
        dish_ingredients_for_params.push({
          ingredient_id:item.ingredient_id,
          unit_type_id: item.unit_type_id,
          weight: parseInt(item.weight)
        })
      })



      Dishes.add(data => {this.data = data}, errors => { this.errors = errors },
          {
            name: this.dish.name,
            description:  this.dish.description,
            recipe_description: this.recipe_description,
            recipe_link: this.dish.recipe_link,
            weight:this.dish.weight,
            protein: this.dish.protein,
            carbohydrate:this.dish.carbohydrate,
            fat:this.dish.fat,
            kilocalories: this.dish.kilocalories,
            glycemic_index:this.dish.glycemic_index,
            is_exotic:this.dish.is_exotic,
            is_active:this.dish.is_active,
            is_micronutrients:this.dish.is_micronutrients,
            dish_categories: dish_categories_for_params,
            dish_types: dish_types_for_params,
            micronutrients: micronutrients_for_params,
            ingredients:dish_ingredients_for_params,
          })
          .then(()=> {
            if (this.data.status === "resource created successfully") {
              this.$router.push({ name: 'dishes' })
            }
          })
    },
    deleteMicronutrient(index){
      this.ingredient.micronutrients.splice(index,1);
    },
    deleteIngredient(index){
      this.dish.ingredients.splice(index,1);
    },
    deleteDishCategory(index){
      this.dish_selected_categories.splice(index,1);
    },
    deleteDishType(index){
      this.types_selected.splice(index,1);
    },
    getCategories(){
      Dishes.category_list(data=> this.dish_categories = data, errors => this.errors = errors)
    },

    fetchOptions ( search) {
      // console.log(options)
      let params = {
        page: 1,
        per_page: 15,
        name: search
      }
      Ingredients.list(data=> this.ingredient_categories = data.data, errors => this.errors = errors, params)
    },
    getIngredientCategories(){
      let params = {
        page: 1,
        per_page: 5,
      }
      Ingredients.list(data=> this.ingredient_categories = data.data, errors => this.errors = errors, params)
    },
    getTypes(){
      Dishes.types(data=> this.types = data, errors => this.errors = errors)
    },
    getUnitTypes(){
      Dishes.unit_types(data=> this.unit_types = data, errors => this.errors = errors)
    },
    getMicroNutrients(){
      Ingredients.micro_nutrients(data=> this.micro_nutrients = data, errors => this.errors = errors)
    },
  }
}
</script>