<template>
  <div class="ingredients single">
    <h3 class="ingredients__title">Ингредиенты</h3>
    <template v-if="data">
      <div class="base-flex-start py-5">
        <h4 class="mr-5">{{data.name}}</h4>
        <router-link :to="{name: 'edit-ingredient', params: {id: data.id}}" custom v-slot="{ navigate }">
          <button @click="navigate" @keypress.enter="navigate" class="base-btn btn-info mr-2" role="link">Изменить</button>
        </router-link>
        <button class="base-btn btn-danger mr-2" @click="deleteIngredient">Удалить</button>
        <router-link :to="{name: 'ingredients'}" class="base-btn btn-warning">Вернуться к списку</router-link>
      </div>
      <div>
        <div class="shadow-sm p-2">
          <p class="base-bold mb-1">Название:  </p>
          <p class="mb-0">{{data.name}}</p>
        </div>
        <div class="shadow-sm p-2">
          <p class="base-bold mb-1">Белки:  </p>
          <p class="mb-0">{{data.protein}}</p>
        </div>
        <div class="shadow-sm p-2">
          <p class="base-bold mb-1">Жиры:  </p>
          <p class="mb-0">{{data.fat}}</p>
        </div>
        <div class="shadow-sm p-2">
          <p class="base-bold mb-1">Углеводы:  </p>
          <p class="mb-0">{{data.carbohydrate}}</p>
        </div>
        <div class="shadow-sm p-2">
          <p class="base-bold mb-1">Ккал:  </p>
          <p class="mb-0">{{data.kilocalories}}</p>
        </div>
        <div class="shadow-sm p-2">
          <p class="base-bold mb-1">Категория:  </p>
          <p class="mb-0">{{data.category.name}}</p>
        </div>
        <div class="shadow-sm p-2">
          <p class="base-bold mb-1">Экзотический:  </p>
          <p class="mb-0">{{data.is_exotic ? 'Да' : 'Нет'}}</p>
        </div>
        <div  class="shadow-sm p-2" v-if="data.is_exotic">
          <p class=" mb-0 pb-3 pt-2 base-bold">Месяцы</p>
          <span class="tag"  v-for="(item, index) in data.months" :key="'month'+index">{{item.name}}</span>
        </div>
      </div>
      <div>
        <p class=" mb-0 pb-3 pt-5 base-bold">Микронутриенты</p>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Название</th>
              <th>Количество</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in data.micronutrients" :key="'micronutrient'+index">
              <td>{{item.name}}</td>
              <td>{{item.pivot.weight}}</td>
            </tr>
          </tbody>
        </table>
      </div>

    </template>
    <Preloader v-else></Preloader>
  </div>
</template>
<script>
import Ingredients from "@/api/Ingredients";
import Preloader from './../../static/Preloader'
export default {
  components: {
    Preloader
  },
  data(){
    return {
      data: null,
      errors: null,
    }
  },
  mounted() {
    this.getIngredient()
  },
  methods: {
    getIngredient(){
      Ingredients.show(data=> this.data = data, errors => this.errors = errors, this.$route.params.id)
    },
    deleteIngredient(){
      Ingredients.delete(data=> this.data = data, errors => this.errors = errors, this.$route.params.id)
      .then(()=>{
        if (this.data.status === 'resource deleted successfully'){
          this.$store.dispatch('SHOW_ALERT', ['Ингредиент успешно удален'])
          this.$router.push({ name: 'ingredients' })
        }
      })
    },
    onPaginate (page) {
      this.page = page
    }
  }
}
</script>