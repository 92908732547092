<template>
  <div class="ingredients single">
    <h3 class="ingredients__title">Блюда</h3>
    <template v-if="data">
      <div class="base-flex-start py-5">
        <h4 class="mr-5">{{data.name}}</h4>
        <router-link :to="{name: 'edit-dish', params: {id: data.id}}" custom v-slot="{ navigate }">
          <button @click="navigate" @keypress.enter="navigate" class="base-btn btn-info mr-2" role="link">Изменить</button>
        </router-link>
        <button class="base-btn btn-danger mr-2" @click="deleteIngredient">Удалить</button>
        <router-link :to="{name: 'dishes'}" class="base-btn btn-warning">Вернуться к списку</router-link>
      </div>
      <div class="row">
        <div class="col-md-8">
          <div>
            <div class="shadow-sm p-2">
              <p class="base-bold mb-1">Название:  </p>
              <p class="mb-0">{{data.name}}</p>
            </div>
            <div class="shadow-sm p-2">
              <p class="base-bold mb-1"> Типы:  </p>
              <p class="mb-0"><span class="tag" v-for="(type, index_type) in data.types" :key="'category_of_dish'+index_type">{{type.name}}</span></p>
            </div>
            <div class="shadow-sm p-2">
              <p class="base-bold mb-1">Категория:  </p>
              <p class="mb-0"><span class="tag" v-for="(category, index_category) in data.category" :key="'category_of_dish'+index_category">{{category.name}}</span></p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <img :src="data.image" :alt="data.name" class="w-100">
        </div>
      </div>

      <div>
        <p class=" mb-0 pb-3 pt-3 base-bold"> Рецепт </p>
        <div class="bg-light rounded mb-2 p-2" v-for="(item, index) in data.recipe_description_arr" :key="index+'recipe'">
          <p class="mb-1 base-bold base-color">Шаг {{index+1}}</p>
          <p class="mb-0">{{item}}</p>
        </div>
      </div>

      <div>
        <p class=" mb-0 pb-3 pt-3 base-bold">Ингредиенты</p>
        <table class="table table-bordered">
          <thead>
          <tr>
            <th>Название</th>
            <th>Количество</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in data.ingredients" :key="'ingredient'+index">
            <td><router-link :to="{name: 'ingredient', params: {id: item.id}}" class="text-link-base">

                {{ item.name }}
            </router-link></td>
            <td>{{item.pivot.weight}} гр.</td>
          </tr>
          </tbody>
        </table>
      </div>



    </template>
    <Preloader v-else></Preloader>
  </div>
</template>
<script>
import Preloader from './../../static/Preloader'
import Dishes from '@/api/Dishes'
export default {
  components: {
    Preloader
  },
  data(){
    return {
      data: null,
      errors: null,
    }
  },
  mounted() {
    this.getDish()
  },
  methods: {
    getDish(){
      Dishes.show(data=> this.data = data, errors => this.errors = errors, this.$route.params.id)
    },
    deleteIngredient(){
      Dishes.delete(data=> this.data = data, errors => this.errors = errors, this.$route.params.id)
          .then(()=>{
            if (this.data.status === 'resource deleted successfully'){
              this.$store.dispatch('SHOW_ALERT', ['Блюда успешно удален'])
              this.$router.push({ name: 'dishes' })
            }
          })
    },
    onPaginate (page) {
      this.page = page
    }
  }
}
</script>