<template>
  <div>
    <h3 class="ingredients__title">Изменить блюдо</h3>
    <form @submit.prevent="addDish" class="ingredients add-ingredient" v-if="dish_old">
      <div class="base-flex-space-between mt-4">
        <p class="mb-0 base-bold">Название: </p>
        <input v-model="dish_old.name" type="text" class="base-input" placeholder="Название блюда">
      </div>
      <div class="base-flex-space-between mt-4">
        <p class="mb-0 base-bold">Bls_code: </p>
        <input v-model="dish_old.bls_code" type="text" class="base-input" readonly>
      </div>
      <div class="base-flex-space-between mt-2">
        <p class="mb-0 base-bold">Описание: </p>
        <textarea  class="base-input rounded" v-model="dish_old.description" rows="2" cols="50" placeholder="Описание блюда ..." required>  </textarea>
      </div>

      <!--    Category-->
<!--      <template>-->
<!--        <div class="base-flex-space-between mt-2 py-3">-->
<!--          <p class="mb-0 base-bold">Категория: </p>-->

<!--          <div class="w-100">-->
<!--              <select class="base-select w-100" v-model="dish_select_category" disabled="true">-->
<!--                <option :value="null" selected style="display: none">Выберите категорию</option>-->
<!--                <option-->
<!--                    v-for="(item, index) in dish_categories"-->
<!--                    :key="'category'+index"-->
<!--                    :value="item"-->
<!--                >{{ item.name }}</option>-->
<!--              </select>-->
<!--              <div class="py-3">-->
<!--              <span class="tag" v-for="(item, index) in dish_old.category" :key="'category'+index">{{item.name}}-->
<!--                &lt;!&ndash;          <img @click="deleteDishCategory(index)" class="tag__close" src="../../../assets/images/close.png"  alt="X">&ndash;&gt;-->
<!--              </span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--      </template>-->

      <!--    Types-->
<!--      <template>-->
<!--        <div class="base-flex-space-between mt-2">-->
<!--          <p class="mb-0 base-bold">Типы: </p>-->
<!--          <select class="base-select" v-model="type_select">-->
<!--            <option :value="null" selected style="display: none">Выберите тип</option>-->
<!--            <option-->
<!--                v-for="(item, index) in types"-->
<!--                :key="'category'+index"-->
<!--                :value="item"-->
<!--            >{{ item.name }}</option>-->
<!--          </select>-->
<!--        </div>-->
<!--        <div class="py-3">-->
<!--        <span class="tag" v-for="(item, index) in dish_old.types" :key="'type'+index">{{item.name}}-->
<!--          <img @click="deleteDishType(index)" class="tag__close" src="../../../assets/images/close.png"  alt="X">-->
<!--        </span>-->
<!--        </div>-->
<!--      </template>-->



      <div class="base-flex-space-between mt-4">
        <p class="mb-0 base-bold">Ссылка на рецепт: </p>
        <input v-model="dish_old.recipe_link" type="text" class="base-input" placeholder="Ссылка на рецепт" required>
      </div>
      <div class="base-flex-space-between mt-4">
        <p class="mb-0 base-bold">Кол-во порций: </p>
        <input v-model="dish_old.servings" type="text" class="base-input" placeholder="Кол-во порций" required>
      </div>
      <div class="base-flex-space-between mt-4">
        <p class="mb-0 base-bold">Вес: </p>
        <input v-model="dish_old.weight" type="text" class="base-input" placeholder="Вес блюда" required>
      </div>





      <template>
  <!--          <div class="mt-2 base-flex-space-between">-->
  <!--            <div>-->
  <!--              <input type="checkbox" id="exotic" name="is_exotic" v-model="dish_old.is_exotic" >-->
  <!--              <label for="exotic" class="add-ingredient__exotic">Экзотический</label>-->
  <!--            </div>-->
  <!--            &lt;!&ndash;      <div v-if="dish.is_exotic" class="add-ingredient__months">&ndash;&gt;-->
  <!--            &lt;!&ndash;        <span v-for="(item, index) in months" :key="'month'+index" class="checkbox-ul">&ndash;&gt;-->
  <!--            &lt;!&ndash;          <input type="checkbox" :id="'month'+item.id" name="month" :value="item.id" v-model="ingredient.months" >&ndash;&gt;-->
  <!--            &lt;!&ndash;          <label :for="'month'+item.id"> {{ item.name }} </label><br>&ndash;&gt;-->
  <!--            &lt;!&ndash;        </span>&ndash;&gt;-->
  <!--            &lt;!&ndash;      </div>&ndash;&gt;-->
  <!--          </div>-->
  <!--          <div class="mt-2 base-flex-space-between">-->
  <!--            <div>-->
  <!--              <input type="checkbox" id="active" name="is_active" v-model="dish_old.is_active" >-->
  <!--              <label for="active" class="add-ingredient__exotic">Активен</label>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--          <div class="mt-2 base-flex-space-between">-->
  <!--            <div>-->
  <!--              <input type="checkbox" id="micro" name="micro" v-model="dish_old.is_micronutrients" >-->
  <!--              <label for="micro" class="add-ingredient__exotic">Микронутриенты</label>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--      <div class="py-4 w-100">-->
  <!--        <div class="row">-->
  <!--          <div class="col mr-2">-->
  <!--            <label class="mr-2">Белки</label>-->
  <!--            <input type="text"  placeholder="" class="base-input" v-model="dish_old.macronutrients.protein" required>-->
  <!--          </div>-->
  <!--          <div class="col mr-2">-->
  <!--            <label class="mr-2">Жиры</label>-->
  <!--            <input type="text"  placeholder="" class="base-input" v-model="dish_old.macronutrients.fat" required>-->
  <!--          </div>-->
  <!--          <div class="col">-->
  <!--            <label class="mr-2">Углеводы</label>-->
  <!--            <input type="text"  placeholder="" class="base-input" v-model="dish_old.macronutrients.carbohydrate" required>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--              <div class="base-flex-space-around mt-3  w-100">-->
  <!--                <div class="col ">-->
  <!--                  <label class="mr-2">Energy amount</label>-->
  <!--                  <input type="text"  placeholder="" class="base-input "  v-model="dish_old.macronutrients.kilocalories" required>-->
  <!--                </div>-->
  <!--                <div class="col ">-->
  <!--                  <label class="mr-2">Glycemic index</label>-->
  <!--                  <input type="text"  placeholder="" class="base-input " v-model="dish_old.macronutrients.glycemic_index">-->
  <!--                </div>-->
  <!--              </div>-->
  <!--      </div>-->

  <!--    <div class="py-4">-->
  <!--      <div class="base-flex-start mb-3">-->
  <!--        <h5 class="mb-0 mr-5">Микронутриенты</h5>-->
  <!--        <button class="base-btn base-btn&#45;&#45;violet" type="button">+ Добавить</button>-->
  <!--      </div>-->
  <!--      <table class="table table-bordered" >-->
  <!--        <thead>-->
  <!--        <tr>-->
  <!--          <th>Название</th>-->
  <!--          <th>Количество</th>-->
  <!--          <th> </th>-->
  <!--        </tr>-->
  <!--        </thead>-->
  <!--        <tbody>-->
  <!--        <tr v-for="(item,index) in ingredient.micronutrients" :key="'ing'+index">-->
  <!--          <td>-->
  <!--            {{item.name}}-->
  <!--          </td>-->
  <!--          <td>-->
  <!--            {{item.weight}} {{item.weight_type}}-->
  <!--          </td>-->
  <!--          <td>-->
  <!--            <button class="base-btn  border bg-light" @click="deleteMicronutrient(index)">Удалить</button>-->
  <!--          </td>-->
  <!--        </tr>-->
  <!--        <tr>-->
  <!--          <td>-->
  <!--            <select class="form-control" v-model="micronutrient">-->
  <!--              <option :value="null" selected style="display: none">Выберите микронутриент</option>-->
  <!--              <option-->
  <!--                  v-for="(item, index) in micro_nutrients"-->
  <!--                  :key="'micronutrients'+index"-->
  <!--                  :value="item"-->
  <!--              >{{ item.name }}</option>-->
  <!--            </select>-->
  <!--          </td>-->
  <!--          <td>-->
  <!--            <input type="text" class="form-control" :placeholder="micronutrient_placeholder" v-model.lazy="micronutrient_value">-->
  <!--          </td>-->
  <!--          <td>-->
  <!--            <button class="base-btn bg-light border">Добавить</button>-->
  <!--          </td>-->
  <!--        </tr>-->

  <!--        </tbody>-->
  <!--      </table>-->
  <!--    </div>-->
</template>

      <div class="py-4">
        <h5 class="mb-2 base-bold">Ингредиенты </h5>
        <table class="table table-bordered" >
          <thead>
          <tr>
            <th>Название</th>
            <th>Ед. измерения </th>
            <th>Количество</th>
            <th>Плотность</th>
            <th>Гр. на ед. измерения</th>
            <th>Способ приготовления</th>
            <th>% отхода</th>
            <th>Коэф. термообработки</th>
            <th>Время обработки (мин) </th>
            <th>Масса брутто</th>
            <th>Масса нетто</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr  v-for="(item,index) in dish_ingredients_list" :key="'ing'+index">
            <td>
              {{item.name }}
            </td>
            <td>{{item.unit_type_name.name}} </td>
            <td>
              {{item.unit_type_amount}}
            </td>
            <td>{{item.ingredient_density}}</td>
            <td>{{item.ingredient_gram_per_unit}}</td>
            <td>{{ item.ingredient_cooking_type_name.name }}</td>
            <td>{{item.ingredient_waste_percentage}}</td>
            <td>{{item.ingredient_heat_ratio}}</td>
            <td>{{item.ingredient_cooking_time}}</td>
            <td>{{item.ingredient_gross_weight}}</td>
            <td>{{item.ingredient_weight}}</td>
            <td>
              <button class="base-btn  border bg-light" @click="deleteIngredient(index)" type="button">Удалить</button>
            </td>
          </tr>
          <tr>
            <td class="px-1 text-center" style="min-width: 200px; " >

<!--              <v-select  v-model="ingredient_selected" :options="options" style="width: 100%;" placeholder="Выберите ингредиент" label="name" @search="fetchOptions">-->
<!--                <template v-slot:option="option">-->
<!--                  {{ option.name }}-->
<!--                </template>-->
<!--              </v-select>-->
              <button class="btn btn-light border" data-toggle="modal" data-target="#exampleModal" type="button">{{ ingredient_selected !== null ? ingredient_selected.name : 'Выберите ингредиент' }}</button>
            </td>
            <td class="px-1 text-center" style="min-width: 150px; " >
              <select class="form-control" v-model="unit_type">
                <option :value="null" selected style="display: none">Выберите тип</option>
                <option
                    v-for="(item, index) in unit_types"
                    :key="'unit_type'+index"
                    :value="item"
                >{{ item.name + '-' + item.volume + item.unit }}</option>
              </select>
            </td>
            <td class="px-1" >
              <input type="number" class="form-control" v-model.lazy="unit_type_amount" placeholder="Количество">
            </td>

            <td class="px-1">
              <input type="number" class="form-control" v-model.lazy="ingredient_density" placeholder="Плотность">
            </td>
            <td class="px-1">
              <input type="text" class="form-control" v-model.lazy="ingredient_gram_per_unit" placeholder="Гр. на ед. измерения" readonly>
            </td>
            <td class="px-1" >
              <select class="form-control" v-model="ingredient_cooking_type">
                <option :value="null" selected style="display: none">Выберите тип</option>
                <option
                    v-for="(item, index) in cooking_types"
                    :key="'cooking_type'+index"
                    :value="item"
                >{{ item.name}}</option>
              </select>
            </td>

            <td class="px-1">
              <input type="number" class="form-control" v-model.lazy="ingredient_waste_percentage" placeholder="% отхода">
            </td>
            <td class="px-1">
              <input type="number" class="form-control" v-model.lazy="ingredient_heat_ratio" placeholder="Коэф. термообработки">
            </td>
            <td class="px-1">
              <input type="number" class="form-control" v-model.lazy="ingredient_cooking_time" placeholder="Время обработки (мин)">
            </td>
            <td class="px-1">
              <input type="number" class="form-control" v-model.lazy="ingredient_gross_weight" placeholder="Масса брутто" readonly>
            </td>
            <td class="px-1">
              <input type="number" class="form-control" v-model.lazy="ingredient_weight" placeholder="Масса нетто" readonly>
            </td>
            <td>
              <button class="base-btn bg-light border" type="button" @click="addIngredient">Добавить</button>
            </td>
          </tr>

          </tbody>
        </table>
      </div>


<!--      Recipe-->
      <div class="base-flex-space-between align-items-start mt-4">
        <p class="mb-0 base-bold">Рецепт: </p>
        <!--        <textarea  class="base-input rounded" v-model="dish_old.recipe_description"   required></textarea>-->
        <div class="w-100">
          <div class="w-100 d-flex align-items-start" v-for="(item, index) in recipe_steps" :key="'recipe_desc'+index">
            <textarea  class="base-input rounded"  v-model="item.text" required>  </textarea>
            <button class="btn btn-light border m-1" type="button" @click="changeRecipeStep(item.text, index)">Изменить</button>
            <button class="btn btn-light border m-1" type="button" @click="deleteRecipeStep(index)">Удалить</button>
          </div>
          <div class="w-100 d-flex align-items-center justify-content-between pt-3 pb-5">
            <textarea  class="base-input rounded"  v-model="recipe_text"   placeholder="Заполните поле">  </textarea>
            <button style="min-width: 150px;" class="base-btn base-btn--violet ml-4"  @click="addStepToRecipe" type="button">+ Добавить шаг</button>
          </div>
        </div>

      </div>

      <div class="w-100 base-flex-end pt-3 pb-5">
        <button class="base-btn border bg-light" type="submit">Сохранить блюдо</button>
      </div>
      <Preloader v-if="false"></Preloader>

    </form>
    <Preloader v-else></Preloader>

    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel"> Выберите ингредиент </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="search" class="d-flex align-items-center">
              <input style="min-width: 70%;" type="text" class="base-input" placeholder="Искать по названию" v-model="name_search" required>
              <button class="base-btn base-btn--violet" type="submit">Поиск</button>
            </form>
            <hr>
            <div>
              <template v-if="ingredients">
                <table class="table shadow-sm mt-4">
                  <thead class="border-0">
                  <tr>
                    <th>Название</th>
                    <th>Действия</th>
                  </tr>
                  </thead>
                  <tbody v-if="ingredients.data">
                  <tr
                      v-for="(item, index) in ingredients.data"
                      :key="'ingredient'+index"
                  >
                    <td>{{item.name}}</td>
                    <td>
                      <button type="button" class="btn btn-light border" @click="selectIngredient(item)"  data-dismiss="modal">Выбрать</button>
                    </td>
                  </tr>
                  </tbody>
                  <Preloader v-else></Preloader>
                </table>
                <template>
                  <paginate
                      :page-count="Math.ceil(ingredients.meta.total/ingredients.meta.per_page)"
                      :click-handler="onPaginate"
                      :prev-text="'<'"
                      :next-text="'>'"
                      :container-class="'pagination mb-5'">
                  </paginate>
                </template>
              </template>

              <Preloader v-else></Preloader>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-light border" data-dismiss="modal">Отмена</button>
          </div>
        </div>
      </div>
    </div>



  </div>

</template>
<script>
import Dishes from "@/api/Dishes";
import Preloader from './../../static/Preloader'
import Ingredients from "@/api/Ingredients"
export default {
  components: {
    Preloader
  },
  data(){
    return {
      recipe_text: null,
      dish_ingredients_list: [],

      selectedBank: null,
      dish_categories: null,
      dish_select_category: null,

      types: null,
      type_select: null,

      micro_nutrients: null,
      micronutrient: null,
      micronutrient_value: null,
      micronutrient_placeholder: null,

      unit_types: null,
      unit_type: null,
      unit_type_amount: 0,


      ingredient_cooking_time: 0,
      ingredient_waste_percentage: 1,
      ingredient_density: 1,
      ingredient_weight: 0,
      ingredient_gram_per_unit: 0,
      ingredient_heat_ratio: 1,
      ingredient_gross_weight: 0,

      ingredient_cooking_type: null,
      cooking_types: null,



      search_ingredient_name: null,
      options: [

      ],

      ingredient_selected: null,
      ingredient_categories: null,
      ingredient: {
        micronutrients:[]
      },

      dish: {
      },
      dish_old: null,
      errors: null,
      data: null,
      recipe_steps: [],
      ingredients: null,
      page: 1,
      per_page: 36,
      name_search: null
    }
  },
  watch: {
    'dish_ingredients_list'(){
      this.dish_old.weight = this.dish_ingredients_list.reduce((total, obj) => obj.ingredient_weight + total,0)
    },
    'unit_type'(){
        this.ingredient_gram_per_unit = parseFloat(this.unit_type.volume) * parseFloat(this.ingredient_density)
        this.ingredient_gross_weight = parseFloat(this.unit_type.volume) * parseFloat(this.ingredient_density) * parseFloat(this.unit_type_amount)
    },
    'unit_type_amount'() {
        this.ingredient_gross_weight = parseFloat(this.unit_type.volume) * parseFloat(this.ingredient_density) * parseFloat(this.unit_type_amount)
    },
    'ingredient_density'() {
      this.ingredient_gram_per_unit = parseFloat(this.unit_type.volume) * parseFloat(this.ingredient_density)
      this.ingredient_gross_weight = parseFloat(this.unit_type.volume) * parseFloat(this.ingredient_density) * parseFloat(this.unit_type_amount)
    },
    'ingredient_gross_weight'() {
      this.ingredient_weight = parseFloat(this.ingredient_gross_weight) * parseFloat(this.ingredient_waste_percentage) * parseFloat(this.ingredient_heat_ratio)
    },
    'ingredient_waste_percentage' () {
      this.ingredient_weight = parseFloat(this.ingredient_gross_weight) * parseFloat(this.ingredient_waste_percentage) * parseFloat(this.ingredient_heat_ratio)
    },
    'ingredient_heat_ratio' () {
      this.ingredient_weight = parseFloat(this.ingredient_gross_weight) * parseFloat(this.ingredient_waste_percentage) * parseFloat(this.ingredient_heat_ratio)
    },

    'ingredient_categories'(){
      this.options = this.ingredient_categories
    },
    // 'dish_select_category'() {
    //   this.dish_old.category.push(this.dish_select_category)
    // },
    'type_select'() {
      this.dish_old.types.push(this.type_select)
    },
    'micronutrient'(){
      this.micronutrient_placeholder = this.micronutrient ? this.micronutrient.unit_type.name : null
    },
    'micronutrient_value'(){
      if (this.micronutrient_value){
        this.ingredient.micronutrients.push({
          micro_id: this.micronutrient.id,
          weight: this.micronutrient_value,
          name: this.micronutrient.name,
          weight_type: this.micronutrient.unit_type.name
        })
        setTimeout(() =>{
          this.micronutrient_value = null
          this.micronutrient = null
        })
      }
    },
    'dish_old.macronutrients.protein'(){
      if (this.dish_old.macronutrients.protein !== null && this.dish_old.macronutrients.carbohydrate !== null && this.dish_old.macronutrients.fat !== null) {
        this.dish_old.macronutrients.kilocalories = (4 * this.dish_old.macronutrients.protein) + (4 * this.dish_old.macronutrients.carbohydrate) + (9 * this.dish_old.macronutrients.fat)
      }
    },
    'dish_old.macronutrients.carbohydrate'(){
      if (this.dish_old.macronutrients.protein !== null && this.dish_old.macronutrients.carbohydrate !== null && this.dish_old.macronutrients.fat !== null) {
        this.dish_old.macronutrients.kilocalories = (4 * this.dish_old.macronutrients.protein) + (4 * this.dish_old.macronutrients.carbohydrate) + (9 * this.dish_old.macronutrients.fat)
      }
    },
    'dish_old.macronutrients.fat'(){
      if (this.dish_old.macronutrients.protein !== null && this.dish_old.macronutrients.carbohydrate !== null && this.dish_old.macronutrients.fat !== null) {
        this.dish_old.macronutrients.kilocalories = (4 * this.dish_old.macronutrients.protein) + (4 * this.dish_old.macronutrients.carbohydrate) + (9 * this.dish_old.macronutrients.fat)
      }
    },
    'dish_old.recipe_description'(){
      if (this.dish_old.recipe_description.length){
        this.recipe_steps = this.dish_old.recipe_description
      }
    },
    page () {
      this.search()
    },
    'dish_old.ingredients'() {
        if (this.dish_old.ingredients.length) {
          this.dish_old.ingredients.map((item) => {
            this.dish_ingredients_list.push({
              id: item.id,
              name: item.name,
              unit_type_amount: item.pivot.unit_type_amount,
              ingredient_cooking_time: item.pivot.cooking_time ,
              ingredient_waste_percentage: item.pivot.waste_percentage,
              ingredient_density: item.pivot.density,
              ingredient_weight: item.pivot.weight,
              unit_type_id: item.pivot.unit_type_id,
              ingredient_cooking_type: item.pivot.cooking_type_ids,
              ingredient_gram_per_unit: item.pivot.gram_per_unit,
              ingredient_heat_ratio: item.pivot.heat_ratio,
              ingredient_gross_weight: item.pivot.gross_weight,
              unit_type_name: this.unit_types.find(obj => obj.id === item.pivot.unit_type_id ),
              ingredient_cooking_type_name: this.cooking_types.find(obj => obj.id === item.pivot.cooking_type_ids )
            })
          })
        }
    }
  },
  mounted() {
    this.getUnitTypes()
    this.getCookingTypes()
    this.getCategories()
    this.getIngredientCategories()
    this.getTypes()
    this.getMicroNutrients()
    this.search()
  },
  methods: {
    addDish(){
      // let micronutrients_for_params = []
      // if (this.dish_old.micronutrients){
      //   this.dish_old.micronutrients.map((item) => {
      //     micronutrients_for_params.push({
      //       micro_id: item.micro_id,
      //       weight: parseFloat(item.weight)
      //     })
      //   })
      // }

      // let dish_categories_for_params = this.dish_select_category.id
      // this.dish_old.category.map((item) => {
      //   dish_categories_for_params.push({
      //     dish_category_id:item.id
      //   })
      // })

      // let dish_types_for_params = []
      // this.dish_old.types.map((item) => {
      //   dish_types_for_params.push({
      //     dish_type_id:item.id
      //   })
      // })

      let dish_ingredients_for_params = []
      this.dish_ingredients_list.map((item) => {
        dish_ingredients_for_params.push({
          ingredient_id:item.id,
          unit_type_id: item.unit_type_id,
          unit_type_amount: item.unit_type_amount,
          cooking_type_ids: item.ingredient_cooking_type,
          cooking_time: item.ingredient_cooking_time,
          waste_percentage: item.ingredient_waste_percentage,
          density: item.ingredient_density,
          weight: item.ingredient_weight,
          gross_weight: item.ingredient_gross_weight,
          gram_per_unit: item.ingredient_gram_per_unit,
          heat_ratio: item.ingredient_heat_ratio
        })
      })





      Dishes.update(data => {this.data = data}, errors => { this.errors = errors }, this.$route.params.id,
          {
            name: this.dish_old.name,
            bls_code: this.dish_old.bls_code,
            description:  this.dish_old.description,
            recipe_link: this.dish_old.recipe_link,
            servings: this.dish_old.servings,
            weight:this.dish_old.weight,

            recipe_description: this.recipe_steps,
            ingredients:dish_ingredients_for_params,
          })
          .then(()=> {
            if (this.data.status === "resource updated successfully") {
              this.$router.push({ name: 'dish', params: {id: this.$route.params.id } })
              this.$store.dispatch('SHOW_ALERT', ['Блюдо успешно обновлено'])
            }
          })
    },
    getCookingTypes(){
      Ingredients.cooking_types(data => this.cooking_types = data, errors => this.errors = errors)
    },
    selectIngredient(item){
      this.ingredient_selected = item
    },
    search() {
      let params = {
        page: this.page,
        per_page: this.per_page,
        name: this.name_search
      }
      Ingredients.list(data=> this.ingredients = data, errors => this.errors = errors, params)
    },
    onPaginate (page) {
      this.page = page
      this.ingredients.data = null
    },
    addStepToRecipe() {
      this.recipe_steps.push({
        text: this.recipe_text
      });
      setTimeout(() => {
        this.recipe_text = ''
      })
    },
    addIngredient(){
      if (this.unit_type_amount && this.ingredient_selected !== null){
        this.dish_ingredients_list.push({
          name: this.ingredient_selected.name,
          id: this.ingredient_selected.id,
          unit_type_amount: this.unit_type_amount,
          ingredient_cooking_time: this.ingredient_cooking_time,
          ingredient_waste_percentage: this.ingredient_waste_percentage,
          ingredient_density: this.ingredient_density,
          ingredient_weight: this.ingredient_weight,
          unit_type_id: this.unit_type.id,
          unit_type_name: {name: this.unit_type.name},
          ingredient_cooking_type: this.ingredient_cooking_type.id,
          ingredient_gram_per_unit: this.ingredient_gram_per_unit,
          ingredient_heat_ratio: this.ingredient_heat_ratio,
          ingredient_gross_weight: this.ingredient_gross_weight,
          ingredient_cooking_type_name: {name: this.ingredient_cooking_type.name}
        })
        setTimeout(() =>{
          this.ingredient_selected = null
          this.unit_type_amount = 0
          this.unit_type = null
          this.ingredient_cooking_time = 0
          this.ingredient_waste_percentage = 1
          this.ingredient_density = 1
          this.ingredient_weight = 0
          this.ingredient_cooking_type = null
          this.ingredient_gram_per_unit = 0
          this.ingredient_heat_ratio = 1
          this.ingredient_gross_weight = 0
        })
      }
    },
    getDish(){
      Dishes.show(data=> this.dish_old = data, errors => this.errors = errors, this.$route.params.id)
    },

    deleteMicronutrient(index){
      this.ingredient.micronutrients.splice(index,1);
    },
    deleteIngredient(index){
      this.dish_ingredients_list.splice(index,1);
    },
    changeRecipeStep(text, index) {
      console.log(text, index)
    },
    deleteRecipeStep(index) {
      this.recipe_steps.splice(index, 1)
    },
    deleteDishCategory(index){
      this.dish_old.category.splice(index,1);
    },
    deleteDishType(index){
      this.dish_old.types.splice(index,1);
    },
    getCategories(){
      Dishes.category_list(data=> this.dish_categories = data, errors => this.errors = errors)
    },

    fetchOptions ( search) {
      // console.log(options)
      let params = {
        page: 1,
        per_page: 100,
        name: search
      }
      Ingredients.list(data=> this.ingredient_categories = data.data, errors => this.errors = errors, params)
    },
    getIngredientCategories(){
      let params = {
        page: 1,
        per_page: 5,
      }
      Ingredients.list(data=> this.ingredient_categories = data.data, errors => this.errors = errors, params)
    },
    getTypes(){
      Dishes.types(data=> this.types = data, errors => this.errors = errors)

    },
    getUnitTypes(){
      Dishes.unit_types(data=> this.unit_types = data, errors => this.errors = errors)
          .then(() => {
            this.getDish()
          })
    },
    getMicroNutrients(){
      Ingredients.micro_nutrients(data=> this.micro_nutrients = data, errors => this.errors = errors)
    },

  }
}
</script>