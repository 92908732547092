import {HTTP} from '@/http'

class Ingredients {
    static add(then, catcher, params = null) { 
        return HTTP.post('/admin/dishes', params)
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }

    static addByIngredients(then, catcher, params = null) {
        return HTTP.post('/admin/dishes-by-ingredients', params)
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }

    static update(then, catcher, id, params = null) {
        return HTTP.put('/admin/dishes/' + id, params)
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }

    static list(then, catcher, params = null) {
        return HTTP.get('/admin/dishes', {params})
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }
    static show(then, catcher, id,  params = null) {
        return HTTP.get('/admin/dishes/'+ id, params)
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }
    static delete(then, catcher, id,  params = null) {
        return HTTP.delete('/admin/dishes/'+ id, params)
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }

    static category_list(then, catcher, params = null) {
        return HTTP.get('/admin/dish-categories', {params})
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }


    static types(then, catcher, params = null) {
        return HTTP.get('/admin/dish-types', {params})
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }

    static unit_types(then, catcher, params = null) {
        return HTTP.get('/admin/unit-types', {params})
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }

    static dishCategories(then, catcher, params = null) {
        return HTTP.get('/admin/dish-categories', {params})
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }


}
export default Ingredients